import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Hautkrebsvorsorge = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Hautkrebsvorsorge"
            noSlider
            {...props}
        >
            <p>
                Wie Sie sicher wissen gibt es verschiedene Hautkrebsarten. Die
                bekanntesten sind der sogenannte weiße und der schwarze
                Hautkrebs.{' '}
            </p>
            <p>
                Je früher wir die Veränderungen erkennen, umso schonender lassen
                sich diese entfernen und umso größer sind die Chancen auf
                Heilung und einem kosmetisch sehr guten Ergebnis.
            </p>
            <p>
                Die Hautkrebsvorsorge ist eine Untersuchung des gesamten
                Hautorgans inclusive der einsehbaren Schleimhäute. Untersucht
                wird natürlich im entkleideten Zustand. Die Dauer hängt ab von
                der Anzahl der vorhandenen Male der Haut und reicht von ca. 5
                Minuten bis zu ca.10 Minuten bei sehr vielen Muttermalen.
            </p>
            <p>
                Einmal monatlich wird eine Selbstuntersuchung Ihrer Haut
                empfohlen. Nutzen Sie das Tageslicht und einen großen Spiegel
                und lernen Sie Ihre Sternenkarte der Hautmale kennen. Notieren
                Sie sich Auffälligkeiten und achten Sie auf Veränderungen im
                Laufe der Zeit. Man kann auch Einzelaufnahmen mit dem Handy
                speichern für spätere Vergleiche.{' '}
            </p>
            <p>
                Das Hautkrebsscreening ergänzt mit fachlicher Expertise Ihre
                Selbstuntersuchung. Es verfolgt das Ziel, Hautkrebsvorstufen so
                frühzeitig zu erkennen, dass diese durch professionelle
                Verfahren ästhetisch und funktionell höchstens eine ganz geringe
                Beeinträchtigung, wenn überhaupt, darstellen.
            </p>
            <p>
                Die Hautkrebsvorsorge stellt immer eine Momentaufnahme dar. Das
                bedeutet, wir können nur am Tag der Untersuchung eine Aussage
                zum Zustand Ihrer Hautmale abgeben. Die Verwendung eines
                Auflichtmikroskopes, dem sogenannten Dermatoskop, ist weltweiter
                Standart und erst dieses ermöglicht die genaue Identifikation
                von feinen Veränderungen eines Leberfleckes und ermöglicht damit
                die Entscheidung ob ein Mal entfernt werden sollte.
            </p>
            <p>
                Zum Beispiel kann sich ein Muttermal 4 Wochen später so weit
                verändert haben, das zu diesem Zeitpunkt eine Entfernung
                angebracht wäre.
            </p>
            <p>Eine Verlaufsdokumentation wäre hier eine große Hilfe. </p>
            <p>
                Wir bieten Ihnen diese Möglichkeit mit der Fotodokumentation
                durch das weltweite System des FotoFinder. Hierbei werden einmal
                pro Jahr Fotoaufnahmen nahezu der gesamten Hautoberfläche
                angefertigt. Bei den jährlichen Folgeaufnahmen vergleicht die
                Software hoch sensibel die aktuellen Muttermale mit den
                Voraufnahmen und zeigt an, inwieweit sich ein Fleck hinsichtlich
                Form, Farbe, Größe verändert hat oder welche neu aufgetreten
                sind.
            </p>
            <p>
                Fragen Sie uns, wir erklären Ihnen weitere Details zu dieser
                weltweit führenden Technik.
            </p>

            <Questions title="Hautkrebsvorsorge" />
        </Layout>
    );
};

export default Hautkrebsvorsorge;
